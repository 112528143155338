//
// Setting up the canvas for manipulation
//
(function () {
    var path = location.pathname;
    console.log(path);

    if (path == "") {

    }

    // var flag=true

    const canvas = document.querySelector('#bg-circle')
    // const c = canvas.getContext('2d')
    // canvas.width = window.innerWidth
    // canvas.height = 480
    if (canvas == null) {
        console.log("nulldes")
        return
    }
    const c = canvas.getContext('2d')
    console.log(c)
    canvas.width = window.innerWidth
    canvas.height = 480

    window.addEventListener('resize', function (event) {
        canvas.width = window.innerWidth
        canvas.height = 480
    });


    // window.addEventListener('resize', function(event){
    //   canvas.width = window.innerWidth
    //   canvas.height = 480
    // });


    //
    // Random Reusable Number Generator
    //
    function randomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }


    //
    // Reusable Circle Creation
    // 
    function Circle(x, y, dx, dy, radius, colour) {

        this.x = x
        this.y = y
        this.dx = dx
        this.dy = dy
        this.radius = radius
        this.colour = colour

        this.draw = function () {
            c.beginPath()
            c.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false)
            c.fillStyle = this.colour
            c.fill()
        }

        this.update = function () {
            if (this.x + this.radius > innerWidth || this.x - this.radius < 0) {
                this.dx = -this.dx
            }
            if (this.y + this.radius > innerHeight || this.y - this.radius < 0) {
                this.dy = -this.dy
            }

            this.x += this.dx
            this.y += this.dy
            this.draw()
        }

    }


    //
    // An Array to store our circles
    // 
    const circleArray = []


    //
    // Generating a specified number of circles
    //
    for (let i = 0; i < 100; i++) {

        // Randomisation of circle properties
        let radius = Math.random() * 70
        let x = Math.random() * (innerWidth - radius * 2) + radius
        let y = Math.random() * (innerHeight - radius * 2) + radius
        let dx = (Math.random() - 0.5) * 5
        let dy = (Math.random() - 0.5) * 5
        let colour = 'rgba(' + randomNumber(100, 255) + ',' + randomNumber(215, 220) + ',' + randomNumber(50, 100) + ', 0.6)'

        //Pushing all the circles into an array
        circleArray.push(new Circle(x, y, dx, dy, radius, colour))

    }


    //
    // Setting up animation frames
    //
    function animate() {

        requestAnimationFrame(animate)

        //Clearing position of previous circle
        c.clearRect(0, 0, innerWidth, innerHeight)

        for (let i = 0; i < circleArray.length; i++) {

            //Firing an update for all of our circles inside the circleArray
            circleArray[i].update()
        }

    }
    animate()
}());